import { createApp } from 'vue'
import App from './App.vue'
// 路由规则
import router from '../router/index.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

const app = createApp(App)
app.use(router)
app.mount('#app')
