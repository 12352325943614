<template>
<div class="companyprofile" >
   
	<div class="class_div_arc1"></div>

    <div class="container">
        <div class="row">

            <div class="col-md-6 ">
                <img src="../assets/image/公司介绍-1.png" style="border-radius: 10%;width: 600px;height: auto;" >
            </div>
            <div class="col-md-6" style="text-align: left;width: 500px;height: 300px;position: relative;top: 100px;left: 100px;">
                <p>公司简介</p> 
                <p>广州云星若邻互联网科技有限公司，成立于2021年5月，在这百花灿烂的日子里，
                    公司定下从一颗种子到小而美再到做大做强的目标。 未来的发展将面向全互联网、
                    科技行业，志向成长为影响久远的科技公司，造福人类。 公司为每位加入云星若邻的伙伴感到荣耀并予以丰厚的回报！</p>
            </div>

            <div class="col-md-6" style="text-align: left;width: 500px;height: 300px;position: relative;top: 100px;left: 100px;">
                <p class="values">核心价值</p> 
                <p>连接科技，为人类的美好未来提供无限的可能</p>
            </div>
            <div class="col-md-6 ">
                <img src="../assets/image/公司介绍-2.png" style="border-radius: 10%;width: 600px;height: auto;position: relative;right: -200px;" >
            </div>
            
            <div class="col-md-6 ">
                <img src="../assets/image/公司介绍-3.png" style="border-radius: 10%;width: 600px;height: auto;" >
            </div>
            <div class="col-md-6" style="text-align: left;width: 500px;height: 300px;position: relative;top: 100px;left: 100px;">
                <p>主营业务</p> 
                <p>云星若邻搭建了功能牛富，易于使用的社交平台，让人与人之间的交流更加智能、便捷、节约成本。</p>
            </div>
            
        </div>
    </div>
     <div class="class_div_arc2"></div>
</div>
</template>

<style>
body{   
    margin: 0 auto;
}
.companyprofile{
    margin: 0 auto;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    z-index: 10;
    .row{
        padding: 30px 0px 0px 0px;
    }
    .col-md-6{
        transition: all 0.1s linear;
    }
    .col-md-6:hover{
        transform: scale(1.1,1.1);
    }
}
.values{
    width: 120px;
    height: 50px;
    color: aliceblue;
    font-size: large;
    border: 1px solid #13B7D1;
    border-radius: 40px;
    background-color: #13B7D1;
    line-height: 1;
}
.class_div_arc1 {
			width: 200px;
			height: 200px;
			background-color: plum;
            background-image: linear-gradient(to bottom,#D7DEEE,#D4C2EF);
			/*四分之一圆*/
			border-radius: 0 0 0 100%;
			/*定位*/
			position: absolute;
			right: 0;
			top: 60;
            z-index: -1;
		}
.class_div_arc2{
    width: 350px;
    height: 350px;
	background-color: plum;
    background-image: linear-gradient(to bottom,#DAFAEC,#D4C2EF);
	border-radius: 50%;
	/*定位*/
	position: absolute;
    bottom: -350px;
	left: -60px;
    z-index: -1;
}
</style>

<script>

</script>