import { createRouter,createWebHashHistory } from "vue-router";
// import App from '../src/App.vue';
import shouye from '../src/components/shouye.vue';
import product from '../src/components/product.vue';
import contact from '../src/components/contact.vue';
import cooperate from '../src/components/cooperate.vue';
import recharge from '../src/components/recharge.vue';
import plan from "../src/components/plan.vue";

const routes = [
    { path:"/",redirect:"/shouye"},
    // {path:"/App",name:"贵司简介"，component:App},
    { path:"/shouye",name:"公司简介",component:shouye},
    { path:"/product",name:"产品介绍",component:product},
    { path:"/contact",name:"联系我们",component:contact},
    { path:"/cooperate",name:"公司广告",component:cooperate},
    { path:"/recharge",name:"广告投放",component:recharge},
    { path:"/plan",name:"万元计划",component:plan},
       
       



]

const router = createRouter({
    //配置路由模式为Hash模式
    history:createWebHashHistory(),
    //路由规则挂载到router的routes属性
    routes:routes

})
export default router

