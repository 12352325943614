<template>
 <div>
    <section>
        <div class="Cornucopia">
            <div class="row" id="row">
                <div class="col-md-6">
                <div class="text" >
                
                <h3 style="font-weight: bolder;">密询聚宝盆</h3>
                <p style="width: 330px;height: 130px;padding:20px 0;">密询聚宝盆是北京云星若邻科技有限公司旗下
                    
                综合的数字化营销服务平台，致力于让个体与企业通过数字化技术激发创造，实现商业的可持续增长。</p>
                <!-- <button style="width: 200px;height: 50px;background-color: #B818F2;border: #B818F2;border-radius: 20px;font-weight: bolder;">
                    <a href="https://mixun.yxrl.cc/" style="text-decoration: none;color: white;">立即前往</a></button> -->
                <button style="width: 200px; height: 50px; background-color: #B818F2; border: #B818F2; border-radius: 20px; font-weight: bolder; color: white;" onclick="window.location.href='https://mixun.yxrl.cc/'">
                    立即前往</button>

                </div>
                </div>
                <div class="col-md-6">
                    <img src="../assets/image/Cornucopia.png" alt="" style="width: 500px;height: 400px;">
                </div>
            </div>
        </div>
    </section>
    <article style="padding-top: 20px;">
        <router-link to="/recharge">
            <img src="../assets/image/recharge.png" alt="" style="width: 100%;">
        </router-link>
        

        <div class="activity">
            <h3>活动时间：</h3>
            <p>本次活动自即日起，仅限首次充值赠送。</p>
            <h3>活动渠道:</h3>
            <p>广告主可通过平台网站、密询聚宝盆参与本次活动。</p>
        </div>
    </article>

    <article style="padding-top: 20px;">
        <router-link to="/plan">
        <img src="../assets/image/plan.png" alt="" style="width: 100%;">
        </router-link>
        <div class="activity">
            <h3>活动时间：</h3>
            <p>本次活动自即日起，至10.18结束。</p>
            <h3>活动渠道:</h3>
            <p>广告主可通过平台网站、密询聚宝盆参与本次活动。</p>
        </div>
    </article>
    <div style="position: relative; top: 40px; ">
        <span >如在活动过程中遇到任何问题或疑问，广告主可通过平台客服渠道进行咨询和反馈。</span>
       <h6 style="padding: 0%;"></h6>
        <span>平台将竭诚为广告主提供优质的服务和支持</span>
    </div>
 </div>
</template>
<style>
.Cornucopia{
    width: 90%;
    height: 400px;
    margin: 0 auto;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
}
#row{
    /* padding: 0 100px; */
   align-items: center;
    margin: 0 auto;
    
}
.text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
.activity{
    padding-top: 20px;
    padding-left: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h3{
        padding-top: 20px;
        color: #6F6F6F;
        font-weight: bolder;
    }
    }
    p{
        text-align: center;
        padding-top: 15px;
        color: #717171;
    }


</style>
<script setup>




</script>