<template>
<div style="border:none;width: 90%; height: 80vh;margin: 0 auto;z-index: 10;">
<div class="row" style="left: 200px;position: relative; ">
    <div class="col-md-12">
    <h3 style="font-weight: 400; padding-bottom: 50px;text-align: left;">联系我们</h3>
    </div>
    <div class="col-md-6">
        <h5 >公共业务邮箱</h5>
        <h6 >public@yxrl.cc</h6>
    </div>
    <div class="col-md-6">
        <h5>公司地址</h5>
        <h6>广州天河区黄村路55号粤安工业园A栋611室</h6>
    </div>
    <div class="col-md-6">
        <h5>人事招聘</h5>
        <h6>hr@yxrl.cc</h6>
    </div>
    <div class="col-md-6">
        <h5 >广告业务邮箱</h5>
        <h6>ad@yxrl.cc</h6>
    </div>
    <div class="col-md-6">
        <h5>客服邮箱</h5>
        <h6>mixun_service@yxrl.cc</h6>
    </div>
</div>
<div class="cir5" style="width: 600px;height: 500px;background-image: linear-gradient(to right,#D4C3EF, #DAFAEC);border-radius:100% 0 0 0;position: absolute; bottom: 50px; right: 0;z-index: -1;"></div>
</div>
<section>
</section>
</template>
<style>
#bottom{
    width: 100%;
    height: 330px;
}
.con{
    width: 200px;
    height: 150px;
    float: left;
    margin-left: 4px;
}
.row{
    margin: 0 auto;
    padding-top: 50px;
    
}
.col-md-6{
    
    text-align: left;
    padding-bottom: 50px;
    

    
}
h6{
        text-align: left;
    }
</style>