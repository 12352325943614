<template>
  
<div>
   
        <Companyprofile></Companyprofile>

      </div>
  </template>
  
  <script>

import Companyprofile from '../components/CompanyProfile.vue';

export default {
  name: 'App',
  components: {
    Companyprofile,
    // footerr
  }
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
article{
  padding: 80px 0px 20px 0px;
}
</style>