<template>
<span>
    ©2021-2024  云星若邻
</span>
<p style="padding: 0;"></p>
<span>
    <img src="../assets/image/beian.png">京公网安备11010502047199号 京ICP备2021025775号-1
</span>
</template>
<style>
span{
    text-align: center;
}
</style>
<script>

</script>