<template>
  <div class="login">
    <img src="../assets/image/logo.png" width="60px" height="40px">
    <img src="../assets/image/云星若邻.png" width="120px" height="60px" style="padding: 0px 10px;">
  </div>
  <div>

  <nav class="nav">
    <div class="menu-item">
      <router-link to="/shouye"> 
        公司介绍
      </router-link>
       
    </div>

    <div class="menu-item">
      <router-link to="/product">产品介绍</router-link>
    </div>

    <div class="menu-item">
      <router-link to="/contact">联系我们</router-link>
    </div>
    <div class="menu-item" >
    
        <li><router-link to="/cooperate" >广告合作</router-link>  </li>
      
    </div>
  </nav>
  </div>
</template>

<script setup>
 
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.login{
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 50px;
}
.nav{
  display: flex;
  float: right;
  position: relative;
  top: -40px;
}
a{
  padding: 0px 20px;
  text-decoration: none;
  color: black;
}
*{
            list-style: none;
            text-decoration:none;
            margin: 0;
            padding: 0;
           
        }
        .cooperate nav{
            width: 100%;
            height: 50px;
            position:absolute;
            /* background-color: black; */
        }
        .cooperate li{
            width: 130px;
            float: right;
            /* 设置文本行高 */
            /* line-height: 50px;
            background-color: black; */
        }
        .cooperate a{
            color: black;
            display: block;
            text-align: center;
        }
        .cooperate a:hover{
            background-color:#BAB9B9;
        }
        /* 下拉菜单隐藏 */
        .cooperate .top_list{
            display: none;
        }
        /* 下拉菜单显示 */
        .cooperate li:hover .top_list{
            display: block;
            z-index: 1;
        }

</style>
