<template>
  <title>云星若邻</title>
  <link rel="shortcut" href="../assets/image/logo.png">
  <div>
  <header>
      <HeadH></HeadH>
  </header>

  <router-view></router-view>

  <article>
    <footerr></footerr>
  </article>
 
</div>
</template>

<script>
import HeadH from './components/HeadH.vue';
import footerr from './components/footerr.vue';

export default {
  name: 'App',
  components: {
    HeadH,
    footerr,
    
  }
}
</script>

<style>
body{
  width: 100%;
    min-width: 1200px;
}

</style>
