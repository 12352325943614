<template>
    <div class="cir1"></div>
    <div class="product" style="width: 90%; height:30vh;display: flex;margin: 0 auto; ">
        
     <header>
       <div class="row" style="padding-left:100px ;padding-top: 50px;">
            <div class="col-md-6" >
            <img src="../assets/image/密询logo.png" alt="" style="width: 200px;height: auto;padding:20px ;">
            </div>

            <div class="col-md-6" style="text-align: left;padding: 30px 0px 0px 150px;">
             <h5>密询</h5>
            一款综合全面的社交应用，开放的不限人数的自建群聊社交，开启元宇宙乐趣!
            <!-- <div style="padding-top: 20px;text-align: left;">
            <h6 style="text-align: left;">· 2023年8月，密询APP1.0版本上线</h6>
            <h6 style="text-align: left;">· 2024年1月，密询更新迭代到2.0版本。</h6>
            </div> -->
            </div>
        </div> 
     </header>
     <div class="cir2"></div>
    </div>
    
    <article class="picture" >
        <span >
            <img src="../assets/image/产品介绍-1.png" alt="" width="200px" height="400px">
            <img src="../assets/image/产品介绍-2.png" alt="" width="200px" height="400px" style="padding: 0 10px;margin-bottom: 50px;">
            <img src="../assets/image/产品介绍-3.png" alt="" width="200px" height="400px">
        </span>
    </article>

    <section >
        <div class="cir3" style="background-color: red;"></div>
        <div class="sec">
            <div class="edition">
            <h4 style="color: aliceblue;font-weight: 800;padding-bottom: 40px;">全 新 版 本</h4>
            <img src="../assets/image/密询倒影效果.png" height="auto" style="padding-right: 150px;" >
            
                <div class="andro">
                <div class="col-md-3 hint-container" @click="download('https://api.yxrl.cc/app1.0.0.apk')">
                    <a href="https://api.yxrl.cc/app1.0.0.apk" style="text-decoration: none;color: black;">
                    <img src="../assets/image/Android.png" alt="" width="50px" height="auto" style="margin: 8px 10px 5px 10px;">
                    <h4>Android</h4>
                    </a>
                    <div class="hint">点击下载</div>
                </div>
                <div class="col-md-3 hint-container">
                    <img src="../assets/image/iOS、MacOS.png" alt="" width="50px" height="auto" style="margin: 8px 10px 5px 10px">
                    <h4>IOS</h4>
                    <div class="hint">敬请期待</div>
                </div>
                
                <div class="col-md-3 hint-container">
                    <img src="../assets/image/Windows.png" alt="" width="50px" height="auto" style="margin: 8px 10px 5px 10px;">
                    <h4>Windows</h4>
                    <div class="hint">敬请期待</div>
                </div>
                <div class="col-md-3 hint-container">
                    <img src="../assets/image/iOS、MacOS.png" alt="" width="50px" height="auto" style="margin: 8px 10px 5px 10px;">
                    <h4>MacOS</h4>
                    <div class="hint">敬请期待</div>

                </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style>
*{
    padding: 0;
    margin: 0;
    z-index: 10;
}
.sec{
    background-color: #fff;
    width: 97.5%;
    margin: 0 auto;
    background-image: url('../assets/image/background.png');
 
}
.edition{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.andro{
   /* background-color: red; */
    height: 180px;
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* justify-content: space-around; */
    /* margin-left: 350px; */
}
.col-md-3{
    background-color: black;
    width: 150px;
    height: 100px;
    background-color: rgba(225, 225, 225, 0.3);
    border-radius: 30px;
    margin: 30px;
    position: relative; /* 使得 hint 能够相对于 .col-md-3 定位 */
    cursor: pointer;

}
.col-md-3:hover {  
    background-color: white; /* 鼠标悬停时背景变为不透明白色 */  
    cursor: pointer;
}
.col-md-3:hover .hint {  
    display: block; /* 鼠标悬停时显示提示文字 */  
}
.hint-container {
    position: relative;
}
.hint {  
    display: none; /* 初始隐藏提示文字 */  
    position: absolute;  
    top: -35px;  
    left: 50%;  
    transform: translateX(-50%);  
    background-color: rgba(0, 0, 0, 0.7);  
    color: white;  
    padding: 5px 10px;  
    border-radius: 10px;  
    white-space: nowrap;
} 
.cir1{
    width: 380px;
	height: 380px;
	background-color: plum;
    background-image: linear-gradient(to bottom,#D8E2EE,#D4C1EF);
	/*四分之一圆*/
	border-radius: 0 0 100% 0;
	/*定位*/
	position: absolute;
	left: 0;
	top: 60px;

}
.cir2{
    width: 200px;
    height: 200px;
    background-image: linear-gradient(to right,#DBFAED,#D4C1EF);
    border-radius: 50%;
    z-index: -1;
    position: absolute;
    right: 310px;
    bottom: 180px;
}
.cir3{
    width: 150PX;
    height: 150PX;
    border-radius: 50%;
    z-index: -1;
    background-image: linear-gradient(to bottom,#DBF9ED,#CFBCE9);
    position: relative;
    bottom: 200px;
    left: 150px;
}
.picture{
    padding-top: 150px
}
</style>
<script>
// import HeadH from './HeadH.vue';

export default{
    name:'product',
    components:{
        
    },
    methods: {
        download(url) {
            window.location.href = url;
        }
    }
   

}
</script>