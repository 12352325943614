<template>
    <img src="../assets/image/recharge.png" style="width: 100%;">
        <div class="test" style="margin: 0 auto;width: 90%; text-align: left;">
        <span>
            <h2 style="font-weight: bold;color: #606060;padding-top: 20px;text-align: left;">广告投放首充活动细则：</h2>
       

<div style="color:#808080;">
<h6>一、活动目的</h6>
<p>本次广告投放首充活动旨在鼓励广告主积极参与广告投放，提升广告效果，同时促进平台与
广告主之间的合作关系。</p>
<h6>二、参与条件</h6>
<p>广告主需为平台注册用户，并完成实名认证。</p>
<p>主要为新客户专享产品，服务于平台0消耗用户</p>
<p>广告主不属于以下类型广告</p>
<p>1、违法或违规内容：任何违反国家法律法规的广告，如涉及色情、暴力、赌博、恐怖主义、
毒品等内容。</p>
<p>2、虚假或误导性广告：包括夸大其词、误导消费者、隐瞒真相等广告。</p>
<p>3、涉及未成年人的不适宜内容：由于未成年人的心理和生理特点，密询会限制涉及未成年</p>
<p>人的广告，尤其是那些可能对未成年人产生不良影响的广告。</p>
<p>4、医疗和药品广告：这类广告通常受到严格的法律和监管要求，包括对广告内容的真实性
和准确性的要求，以及广告发布前的审批程序。因此，会限制或禁止某些医疗和药品广告。</p>
<p>5、烟草和酒类广告：因为吸烟和饮酒对健康有害。</p>
<p>6、涉及政治或宗教的广告。</p>
<h6>三、首充金额与奖励机制</h6>
<p>奖励机制：暂无金额限制，广告主完成充值的第一笔任意金额，将获得充值额 30%的赠送
金额，可用于后续广告投放。充值后，赠送的金额将于两个工作日内自动到账户。赠送金额
仅用于投放不可提现或退款</p>
<h6>四、活动时间</h6>
<p>本次活动自即日起，仅限首次充值赠送。</p>
<h6>五、活动渠道</h6>
<p>广告主可通过平台官方网站、密询聚宝盆参与本次活动。</p>
<h6>六、注意事项</h6>
<p>广告主需确保所充值的资金来源合法合规，不得使用任何非法手段进行充值。</p>
<p>广告主需遵守平台广告投放规定，不得发布违法、违规或不良信息等内容。</p>
<p>广告主在享受活动奖励的同时，仍需按照平台规定的广告计费方式进行结算。</p>
<p>广告主购买后可进行退款，也会将扣除相应比例的赠送金额。</p>
<p>主办方保留对如果活动内容发生变化，主办方会及时公告并通知用户，主办方有活动规则进
行变更和解释的权利。</p>
<h6>七、联系方式与客服支持</h6>
<p>如在活动过程中遇到任何问题或疑问，广告主可通过平台客服渠道进行咨询和反馈。平台将
竭诚为广告主提供优质的服务和支持。</p>
</div>
        </span>
    
    </div>
</template>
<style scoped>
p{
   text-align: left;}
h6{
    text-align: left;
    padding-top: 20px;
    font-weight: bold;
   }

</style>
<script>

</script>
